<template
  @click:outside="closeThisMenu"
>
  <v-menu
    v-model="isMenuOpen"
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        @click="editModalClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-pencil
          </v-icon>
          Modifica
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="deleteModalClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-delete
          </v-icon>
          Elimina
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentMenu',
  props: {
    showMenu: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  watch: {
    showMenu(newVal) {
      this.isMenuOpen = newVal
    },
    isMenuOpen(newVal) {
      if (newVal === false) {
        this.$emit('closeMenu')
      }
    },
  },
  methods: {
    editModalClick() {
      this.$emit('editDocument')
    },
    deleteModalClick() {
      this.$emit('deleteDocument')
    },
    closeThisMenu() {
      this.$emit('closeMenu')
    },
  },
}
</script>
