import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c(VList,[(!_vm.noProcedureUpdate && !_vm.isArchived)?_c(VListItem,{on:{"click":_vm.editModalClick}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Modifica ")],1)],1):_vm._e(),(!_vm.isArchived)?_c(VListItem,{on:{"click":_vm.showInviteClick}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-arrow-left ")]),_vm._v(" Invita Cliente ")],1)],1):_vm._e(),(!_vm.noInviteProfessional && !_vm.isArchived)?_c(VListItem,{on:{"click":_vm.showInviteProClick}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-account-arrow-left ")]),_vm._v(" Invita Professionista ")],1)],1):_vm._e(),(!_vm.noProcedureArchive && !_vm.isArchived)?_c(VListItem,{on:{"click":_vm.archiveModalClick}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-text-box-check ")]),_vm._v(" Archivia ")],1)],1):_vm._e(),(_vm.isArchived)?_c(VListItem,{on:{"click":_vm.reactivate}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Riattiva Pratica ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }