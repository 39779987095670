<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Elimina Cartella</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>L'eliminazione della cartella è permanente e coinvolge anche i file inclusi in essa, accertarsi di voler continuare.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            :disabled="loading"
            @click="closeModal"
          >
            Chiudi
          </v-btn>
          <v-spacer />
          <v-btn
            color="red"
            dark
            class="ma-2"
            :disabled="noDelete"
            :loading="loading"
            @click.prevent="destroyFolder"
          >
            Elimina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'DeleteFolder',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    folderData: {
      type: Object,
      default: () => ({}),
    },
    apiMethod: {
      type: String,
      required: true,
    },
    apiTargetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      noDelete: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  methods: {
    async destroyFolder() {
      try {
        this.loading = true
        await this.$api[this.apiMethod](this.apiTargetId, this.folderData.id, this.folderData)

        await this.$store.dispatch('set_alert', { msg: 'Cartella Eliminata con successo', type: 'success' })
        this.$emit('reload-data')
        this.closeModal()
      } catch (e) {
        this.loading = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    closeModal() {
      this.$emit('close')
      this.noDelete = false
      this.testWord = ''
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
