<template>
    <span>
    <v-tooltip left v-if="user">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                :color=color
                :size=size
                v-bind="attrs"
                v-on="on"
            >
                <v-img
                    v-if="getAvatar(user.id)"
                    :src="getAvatar(user.id)"
                >
                </v-img>
                <span
                    v-else
                >
                    <span>{{ user.initials }}</span>
                </span>
            </v-avatar>
        </template>
        <span>{{ user.fullName }} - {{ user.roleText }}</span>
    </v-tooltip>
    <v-tooltip left v-if="invite">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                :color=color
                :size=size
                v-bind="attrs"
                v-on="on"
            >
                <span>I</span>
            </v-avatar>
        </template>
        <span>{{ invite.target_user_email }} - Invito</span>
    </v-tooltip>
    </span>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "ProcedureUsersAvatar",
    components: {},
    computed: {
        ...mapGetters(['userAvatarList'])
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-2'
        },
        size: {
            type: Number,
            default: 40
        },
        user: Object,
        invite: Object,
        tooltip: {
            type : Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    methods: {
        getAvatar(uid) {
            let check = this.userAvatarList.find(({user_id}) => user_id === uid)
            if(check !== undefined){
                return check.url
            }
            return false
        }
    }
}
</script>