<template>
  <v-card
    v-click-outside="onClickOutside"
    class="d-flex flex-column"
    height="100%"
    @drop="onDrop"
    @dragover="onDragEnter"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @click="onClickInside"
    @dblclick="goToFolder(goToFolderTargetId, folderObj.unique_ref)"
    @contextmenu.prevent="showContextualMenu"
  >
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex align-center"
          cols="10"
        >
          <v-icon
            left
          >
            mdi-folder
          </v-icon>
          <h6>
            {{ folderObj.name }}
          </h6>
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <v-icon
            v-if="folderPermissions.length > 0"
            left
            small
            color="#4caf50"
          >
            mdi-lock
          </v-icon>
          <folder-menu
            v-if="!isArchived && (isOwner || isCreator || hasPermission('write') || hasPermission('delete'))"
            :folder-data="folderObj"
            :show-menu="isShow.contextualMenu"
            :can-manage-permissions="isOwner || isCreator || hasPermission('write')"
            :can-write="isOwner || isCreator || hasPermission('write')"
            :can-delete="isOwner || isCreator || hasPermission('delete')"
            @closeMenu="closeContextualMenu"
            @editDocument="isShow.edit=true"
            @permissionsFolder="isShow.permissions=true"
            @deleteDocument="isShow.delete=true"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <edit-folder
      :api-target-id="apiTargetId"
      :api-method="apiRoutes.editApiMethod"
      :is-show="isShow.edit"
      :folder-data="folderObj"
      @close="isShow.edit=false"
      @reload-data="reloadData"
    />
    <delete-folder
      :api-target-id="apiTargetId"
      :api-method="apiRoutes.deleteApiMethod"
      :is-show="isShow.delete"
      :folder-data="folderObj"
      @close="isShow.delete=false"
      @reload-data="reloadData"
    />
    <permissions-folder
      :api-target-id="apiTargetPermId > 0 ? apiTargetPermId : apiTargetId"
      :api-get-method="apiRoutes.permGetApiMethod"
      :api-edit-method="apiRoutes.permEditApiMethod"
      :api-delete-method="apiRoutes.permDeleteApiMethod"
      :is-show="isShow.permissions"
      :folder-data="folderObj"
      @open-manage-permissions="isShow.permissionsManager=true"
      @close="isShow.permissions=false"
      @reload-data="reloadData"
    ></permissions-folder>
    <manage-permissions-folder
      :api-target-id="apiTargetPermId > 0 ? apiTargetPermId : apiTargetId"
      :api-get-method="apiRoutes.permGetApiMethod"
      :api-set-method="apiRoutes.permSetApiMethod"
      :is-show="isShow.permissionsManager"
      :folder-data="folderObj"
      @close="isShow.permissionsManager=false"
      @reload-data="reloadData"
    ></manage-permissions-folder>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import FolderMenu from '@/components/menus/folder-menu.vue'

import EditFolder from '@/components/modals/edit-folder.vue'
import DeleteFolder from '@/components/modals/delete-folder.vue'

import PermissionsFolder from '@/components/modals/permissions-folder.vue'
import ManagePermissionsFolder from '@/components/modals/manage-permissions-folder.vue'

import vClickOutside from 'v-click-outside'

export default {
  name: 'FolderCard',
  components: {
    ManagePermissionsFolder,
    PermissionsFolder,
    EditFolder,
    DeleteFolder,
    FolderMenu,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    folderObj: {
      type: Object,
      required: true,
    },
    folderRoute: {
      type: String,
      required: true,
    },
    folderPermissions: {
      type: Array,
      default: () => [],
    },
    goToFolderTargetId: {
      type: Number,
      required: true,
    },
    apiRoutes: {
      type: Object,
      required: true,
    },
    apiTargetId: {
      type: Number,
      required: true,
    },
    apiTargetPermId: {
      type: Number,
      required: false,
      default: 0,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        fileDownloadAll: {},
      },
      isShow: {
        upload: false,
        edit: false,
        delete: false,
        permissions: false,
        permissionsManager: false,
        contextualMenu: false,
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'loggedUserCompany']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    isCreator() {
      if (this.folderObj.user_id) {
        return this.folderObj.user_id === this.loggedUser.id
      }

      return false
    },
  },
  methods: {
    onDragEnter(e) {
      e.preventDefault()
      this.$el.classList.add('selectedFolder')
    },
    onDragLeave(e) {
      e.preventDefault()
      this.$el.classList.remove('selectedFolder')
    },
    async onDrop(e) {
      const dropEntity = e.dataTransfer.getData('dropEntity')
      const dropType = e.dataTransfer.getData('dropType')
      const documentId = e.dataTransfer.getData('documentId')
      const procedureId = e.dataTransfer.getData('procedureId')
      const groupId = e.dataTransfer.getData('groupId')
      const fileId = e.dataTransfer.getData('fileId')

      // console.log(e.dataTransfer)

      // Exception for direct files
      if (dropEntity === 'file') {
        if (dropType === 'group') {
          // If it's a group file
          try {
            // Emit a signal to the parent component to tell it that the file has been dropped
            this.$emit('droppedFile', fileId)
            const dataToSend = {
              folder_id: this.folderObj.id,
            }

            await this.$api[this.apiRoutes.editFileApiMethod](fileId, dataToSend)

            this.$emit('reloadData')

            this.$el.classList.remove('selectedFolder')

            await this.$store.dispatch('set_alert', { msg: 'File spostato con successo', type: 'success' })
          } catch (ex) {
            this.loading = false
            await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
          }
        }
      } else {
        let entityId = null
        if (procedureId !== '' && procedureId !== undefined) {
          entityId = procedureId
        } else if (groupId !== '' && groupId !== undefined) {
          entityId = groupId
        }

        console.log(entityId)

        try {
          this.$emit('droppedItem', documentId)
          const dataToSend = {
            folder_id: this.folderObj.id,
          }

          await this.$api[this.apiRoutes.editDocumentApiMethod](entityId, documentId, dataToSend)

          this.$emit('reloadData')
          this.$el.classList.remove('selectedFolder')
          await this.$store.dispatch('set_alert', { msg: 'Documento Spostato con successo', type: 'success' })
        } catch (ex) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    onClickInside() {
      // add the class to the card border
      this.$el.classList.add('selectedFolder')
    },
    onClickOutside() {
      // remove the class from the card border
      this.$el.classList.remove('selectedFolder')
      this.closeContextualMenu()
    },
    reloadData() {
      this.$emit('reloadData')
    },
    goToFolder(id, folderUniqueRef) {
      this.$router.push({
        name: this.folderRoute,
        params: {
          id,
          fid: folderUniqueRef,
        },
      })
    },
    hasPermission(perm) {
      let retPermission = false
      for (let i = 0; i < this.folderPermissions.length; i += 1) {
        const permission = this.folderPermissions[i]
        switch (permission.entity_type) {
          case 'user':
            if (permission.entity_id === this.loggedUser.id) {
              return !!permission[perm]
            }
            break
          case 'company':
            if (permission.entity_id === this.loggedUser.company_id) {
              retPermission = permission[perm]
            }
            break
          case 'subgroup':
            for (let j = 0; j < this.loggedUserCompany.subgroups.length; j += 1) {
              const subgroup = this.loggedUserCompany.subgroups[j]
              if (permission.entity_id === subgroup.id) {
                retPermission = permission[perm]
              }
            }
            break
          default:
            // do nothing
            break
        }
      }

      // concert to boolean
      return !!retPermission
    },
    closeContextualMenu() {
      this.isShow.contextualMenu = false
    },
    showContextualMenu(e) {
      e.preventDefault()
      this.isShow.contextualMenu = true
    },
  },
}
</script>

<style scoped>
.v-card {
  user-select: none;
}
.selectedFolder {
  background-color: #c2e7ff !important;
}
</style>
