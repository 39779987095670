<template>
    <span>
    <v-tooltip left v-if="company">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                :color=color
                :size=size
                v-bind="attrs"
                v-on="on"
            >
                <v-img
                    v-if="getAvatar(company.id)"
                    :src="getAvatar(company.id)"
                >
                </v-img>
                <span
                    v-else
                >
                    <span>{{ company.initials }}</span>
                </span>
            </v-avatar>
        </template>
        <span>{{ company.name }}</span>
    </v-tooltip>
    <v-tooltip left v-if="invite">
        <template v-slot:activator="{ on, attrs }">
            <v-avatar
                :color=color
                :size=size
                v-bind="attrs"
                v-on="on"
            >
                <span>I</span>
            </v-avatar>
        </template>
        <span>{{ invite.target_user_email }} - Invito</span>
    </v-tooltip>
    </span>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: "ProcedureCompaniesAvatar",
    components: {},
    computed: {
        ...mapGetters(['companyAvatarList'])
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-2'
        },
        size: {
            type: String,
            default: '40'
        },
        company: Object,
        invite: Object
    },
    data() {
        return {}
    },
    methods: {
        getAvatar(cid) {
            let check = this.companyAvatarList.find(({company_id}) => company_id === cid)
            if(check !== undefined){
                return check.url
            }
            return false
        }
    }
}
</script>