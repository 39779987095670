<template>
  <v-card
    class="pa-2"
    @contextmenu.prevent="showContextualMenu"
  >
    <v-card-title
      v-if="!noTitleBlock"
      class="subheading font-weight-bold info--text"
    >
      <span>{{ procedureObj.title }}</span>

      <v-spacer></v-spacer>
      <procedure-menu
        v-if="loggedUser.role === 'professional'"
        :is-archived="procedureObj.status === 'archived'"
        :no-procedure-update="noProcedureUpdate"
        :no-procedure-archive="noProcedureArchive"
        :no-invite-professional="noInviteProfessional"
        :show-menu="isShow.contextualMenu"
        @closeMenu="closeContextualMenu"
        @editProcedure="isShow.edit=true"
        @archiveProcedure="isShow.archive=true"
        @showInvite="isShow.invite=true"
        @showInvitePro="isShow.invitePro=true"
        @reactivate="isShow.reactivate=true"
      />
    </v-card-title>
    <v-card-subtitle
      v-if="!noSubtitleBlock"
    >
      {{ procedureObj.reference }}
    </v-card-subtitle>
    <v-divider
      v-if="!noSubtitleBlock && !noTitleBlock"
    />
    <v-card-text
      v-if="!headerSmall"
    >
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="9"
        >
          <div>
            {{ procedureObj.createdAtFormatted }}
          </div>
          <div>
            {{ procedureObj.description }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          class="text-right"
        >
          <span
            v-for="(user,uIdx) in procedureObj.users"
            :key="'B'+uIdx"
            class="px-1"
          >
            <procedure-users-avatar
              :user="user"
            ></procedure-users-avatar>
          </span>
          <span
            v-for="(company,uIdx) in procedureObj.companies"
            :key="'C'+uIdx"
            class="px-1"
          >
            <procedure-companies-avatar
              :company="company"
            ></procedure-companies-avatar>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="9"
          class="d-flex align-center"
        >
          <v-row
            class="match-height"
          >
            <v-col
              v-if="!noStatusIcon && procedureObj.status !== 'archived'"
              cols="12"
              sm="12"
              md="2"
            >
              <generic-info-avatar
                title="Stato"
                total="Scaduta"
                color="error"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <generic-info-avatar
                :title="documentsCounterLabel"
                :total="procedureObj.documents_count"
                color="success"
                icon="mdi-file-document"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="2"
            >
              <generic-info-avatar
                :title="documentFilesCounterLabel"
                :total="procedureObj.document_files_count"
                color="info"
                icon="mdi-file"
              />
            </v-col>
            <v-col
              v-if="!noSurveyIcon"
              cols="12"
              sm="12"
              md="2"
            >
              <generic-info-avatar
                :title="surveysCounterLabel"
                :total="procedureObj.surveys_count"
                color="warning"
                icon="mdi-poll"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          class="d-flex justify-end align-end"
        >
          <v-btn
            v-if="procedureEnterBtn"
            color="primary"
            class="mt-3 hidden-xs-only"
            :to="{name:procedureRoute,params:{id:procedureObj.id}}"
          >
            Entra
          </v-btn>
          <v-btn
            v-if="procedureEnterBtn"
            color="primary"
            class="mt-3 hidden-sm-and-up"
            :to="{name:procedureRoute,params:{id:procedureObj.id}}"
            block
          >
            Entra
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <edit-procedure
      :is-show="isShow.edit"
      :procedure-data="procedureObj"
      @close="isShow.edit=false"
      @reload-data="reloadData"
    />
    <archive-procedure
      :is-show="isShow.archive"
      :procedure-data="procedureObj"
      :redirect-to-detail="redirectToDetail"
      @close="isShow.archive=false"
    />
    <reactivate-procedure
      :is-show="isShow.reactivate"
      :procedure-data="procedureObj"
      :redirect-to-detail="redirectToDetail"
      @close="isShow.reactivate=false"
    />
    <invite-user
      :is-show="isShow.invite"
      :procedure-data="procedureObj"
      @close="isShow.invite=false"
    />
    <invite-professional
      :is-show="isShow.invitePro"
      :procedure-data="procedureObj"
      @close="isShow.invitePro=false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcedureMenu from '@/components/menus/procedure-menu.vue'

import EditProcedure from '@/components/modals/edit-procedure.vue'
import ArchiveProcedure from '@/components/modals/archive-procedure.vue'
import ReactivateProcedure from '@/components/modals/reactivate-procedure.vue'
import InviteUser from '@/components/modals/invite-user.vue'
import InviteProfessional from '@/components/modals/invite-professional.vue'

import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar.vue'
import ProcedureCompaniesAvatar from '@/components/procedure/procedure-companies-avatar.vue'
import GenericInfoAvatar from '@/components/avatars/generic-info-avatar.vue'

export default {
  name: 'DocumentCard',
  components: {
    GenericInfoAvatar,
    ProcedureMenu,

    ProcedureUsersAvatar,
    ProcedureCompaniesAvatar,

    EditProcedure,
    ArchiveProcedure,
    ReactivateProcedure,
    InviteUser,
    InviteProfessional,
  },
  props: {
    procedureObj: {
      type: Object,
      required: true,
    },
    procedureEnterBtn: {
      type: Boolean,
      default: true,
    },
    redirectToDetail: {
      type: Boolean,
      default: false,
    },
    noStatusIcon: {
      type: Boolean,
      default: false,
    },
    noSurveyIcon: {
      type: Boolean,
      default: false,
    },
    noProcedureUpdate: {
      type: Boolean,
      default: false,
    },
    noInviteProfessional: {
      type: Boolean,
      default: false,
    },
    noProcedureArchive: {
      type: Boolean,
      default: false,
    },
    noTitleBlock: {
      type: Boolean,
      default: false,
    },
    noSubtitleBlock: {
      type: Boolean,
      default: false,
    },
    headerSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: {
        edit: false,
        archive: false,
        invite: false,
        invitePro: false,
        reactivate: false,
        contextualMenu: false,
      },
      mapExpirationChip: {
        expired: 'Scaduta',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedUser', 'partnerSettings']),
    procedureRoute() {
      return this.procedureObj.status === 'archived' ? 'archivedProcedureView' : 'procedureView'
    },
    documentsCounterLabel() {
      return this.partnerSettings?.dictionary?.main?.[this.procedureObj.documents_count === 1 ? 'document' : 'documents'] ?? (this.procedureObj.documents_count === 1 ? 'Documento' : 'Documenti')
    },
    documentFilesCounterLabel() {
      return this.partnerSettings?.dictionary?.main?.[this.procedureObj.document_files_count === 1 ? 'file' : 'files'] ?? (this.procedureObj.document_files_count === 1 ? 'File' : 'Files')
    },
    surveysCounterLabel() {
      return this.partnerSettings?.dictionary?.main?.[this.procedureObj.surveys_count === 1 ? 'survey' : 'surveys'] ?? (this.procedureObj.surveys_count === 1 ? 'Questionario' : 'Questionari')
    },
  },
  methods: {
    async reloadData() {
      await this.$store.dispatch('getProcedure', this.procedureObj.id)
    },
    closeContextualMenu() {
      this.isShow.contextualMenu = false
    },
    showContextualMenu(e) {
      e.preventDefault()
      this.isShow.contextualMenu = true
    },
  },
}
</script>
