<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Archivia Pratica</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>Archiviando la pratica, i clienti non potranno più caricare documenti in essa, ma potranno solo consultarla.</p>
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                class="ma-2"
                :disabled="loading"
                @click="closeModal"
              >
                Chiudi
              </v-btn>
              <v-btn
                color="red"
                dark
                class="ma-2"
                :loading="loading"
                @click.prevent="archiveProcedure"
              >
                Archivia
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'ArchiveProcedureModal',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    procedureData: {
      type: Object,
      required: true,
    },
    redirectToDetail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async archiveProcedure() {
      try {
        this.loading = true
        await this.$api.archiveProcedure(this.procedureData.id)
        await this.$store.dispatch('getProcedures')
        await this.$store.dispatch('set_alert', { msg: 'Pratica archiviata con successo', type: 'success' })
        await this.$store.dispatch('setProductPercentages')
        await this.$store.dispatch('setProductFunctionalities')
        this.closeModal()
        if (this.redirectToDetail) {
          await this.$router.push({ name: 'archivedProcedureView', params: { id: this.procedureData.id } })
        }
      } catch (e) {
        this.loading = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>
