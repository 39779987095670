<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload File</span>
        </v-card-title>
        <v-card-text>
          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-complete="getUploadResponse"
          ></vue-dropzone>
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              class="ma-2"
              :disabled="loading"
              @click="closeModal"
            >
              Chiudi
            </v-btn>
            <v-btn
              class="ma-2"
              :color="btnColor"
              :dark="btnDark"
              :loading="loading"
              @click="launchUpload"
            >
              Carica
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapGetters } from 'vuex'

export default {
  name: 'UploadFile',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    uploadUrl: {
      type: String,
      default: 'url',
      required: true,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    dropzoneOptions() {
      if (this.isPluginVersion) {
        return {
          dictDefaultMessage: 'Trascina i file oppure clicca per caricare',
          url: 'url',
          thumbnailWidth: 150,
          maxFilesize: 20,
          autoProcessQueue: false,
          headers: { Authorization: `Bearer ${this.$store.state.auth.token}` },
          paramName: 'images',
        }
      }

      return {
        dictDefaultMessage: 'Trascina i file oppure clicca per caricare',
        url: 'url',
        thumbnailWidth: 150,
        maxFilesize: 20,
        autoProcessQueue: false,
        headers: { Authorization: `Bearer ${this.$store.state.auth.token}` },
        paramName: 'images',
      }
    },
  },
  watch: {
    isShow() {
      if (this.isShow === true) {
        this.setDropzoneUrl()
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.dropzone.options.url = this.uploadUrl
        }
      }
    },
  },
  methods: {
    async showError(file, message) {
      await this.$store.dispatch('set_alert', { msg: message, type: 'error' })
    },
    async getUploadResponse(resp) {
      const response = JSON.parse(resp.xhr.response)
      if (response.status === 'success') {
        await this.$emit('reload-data')
        this.loading = false
        await this.closeModal()
      } else {
        await this.$store.dispatch('set_alert', { msg: response.message, type: 'error' })
        this.loading = false
        await this.closeModal()
      }
    },
    closeModal() {
      this.$emit('close')
      this.$refs.myVueDropzone.removeAllFiles()
      this.loading = false
    },
    launchUpload() {
      this.loading = true
      this.$refs.myVueDropzone.processQueue()
    },
    setDropzoneUrl() {
      this.dropzoneOptions.url = this.uploadUrl
    },
  },
}
</script>

<style scoped>

</style>
