<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica Cartella</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="editFolder"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localFolderData.name"
                  label="Nome"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localFolderData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  :color="btnColor"
                  :dark="btnDark"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="editFolder"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'EditFolder',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    folderData: {
      type: Object,
      default: () => ({}),
    },
    apiMethod: {
      type: String,
      required: true,
    },
    apiTargetId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      localFolderData: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  watch: {
    isShow(newShow) {
      if (newShow) {
        this.localFolderData = JSON.parse(JSON.stringify(this.folderData))
      }
    },
  },
  methods: {
    async editFolder() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api[this.apiMethod](this.apiTargetId, this.localFolderData.id, this.localFolderData)

          this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Cartella Modificata con successo', type: 'success' })
          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
