<template>
  <div>
    <v-skeleton-loader
      v-if="isDropped"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-card
      v-if="!isDropped"
      :draggable="isDraggable"
      class="mt-3 d-flex flex-column"
      :style="urgencyCard(documentObj.priority)"
      height="100%"
      @click.prevent
      @dblclick="goToDocumentDetail(documentObj.id)"
      @contextmenu.prevent="showContextualMenu"
      @dragstart="onDragStart"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="10"
          >
            <h5>{{ documentObj.title }}</h5>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="2"
          >
            <document-menu
              v-if="loggedUser.role === 'professional' && !documentReadOnly && canHandleResource"
              :show-menu="isShow.contextualMenu"
              @closeMenu="closeContextualMenu"
              @editDocument="isShow.edit=true"
              @deleteDocument="isShow.delete=true"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="overflow-y: auto; min-height:70px">
        <v-row>
          <v-col>
            <div>
              {{ documentObj.description }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions>
        <template>
          <v-chip
            v-if="!noAction"
            label
            :color="documentObj.document_setting_status.color"
            :text-color="documentObj.document_setting_status.color_text"
          >
            {{ documentObj.document_setting_status.name }}
          </v-chip>
          <v-spacer></v-spacer>

          <document-action-menu
            is-plugin-version
            :document-obj="documentObj"
            :upload-enabled="uploadEnabled"
            :is-workable-doc="isWorkable"
            :is-read-only="documentReadOnly"
            :document-route="documentRoute"
            :loading="loading.fileDownloadAll[documentObj.id]"
            :current-procedure-id="currentProcedureId"
            @handleUpload="handleUploadFiles"
            @downloadAll="downloadAllWithAxios"
          />
        </template>
      </v-card-actions>
      <upload-file
        :procedure-id="procedureId"
        :upload-url="uploadUrl"
        :is-show="isShow.upload"
        @close="isShow.upload=false"
        @reload-data="reloadData"
      />
      <edit-document
        is-plugin-version
        :no-priority="noPriority"
        :no-action="noAction"
        :no-status="noStatus"
        :is-show="isShow.edit"
        :document-data="documentObj"
        @close="isShow.edit=false"
        @reload-data="reloadData"
      />
      <delete-document
        :is-show="isShow.delete"
        :document-data="documentObj"
        is-plugin-version
        @close="isShow.delete=false"
        @reload-data="reloadData"
      />
      <confirm-document
        :is-show="isShow.confirm"
        :document-data="documentObj"
        @close="isShow.confirm=false"
        @reload-data="reloadData"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import DocumentMenu from '@/components/menus/document-menu.vue'
import DocumentActionMenu from '@/components/menus/document-action-menu.vue'

import UploadFile from '@/components/modals/upload-file.vue'

import EditDocument from '@/components/modals/edit-document.vue'
import DeleteDocument from '@/components/modals/delete-document.vue'
import ConfirmDocument from '@/components/modals/confirm-document.vue'

export default {
  name: 'DocumentCard',
  components: {
    ConfirmDocument,
    DeleteDocument,
    DocumentActionMenu,
    DocumentMenu,
    EditDocument,
    UploadFile,
  },
  props: {
    documentObj: {
      type: Object,
      required: true,
    },
    documentReadOnly: {
      type: Boolean,
      default: false,
    },
    documentRoute: {
      type: String,
      default: 'procedureDocumentsView',
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    procedureId: {
      type: Number,
      default: null,
    },
    noPriority: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    noStatus: {
      type: Boolean,
      default: false,
    },
    canHandleResource: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isDropped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        fileDownloadAll: {},
      },
      isShow: {
        upload: false,
        edit: false,
        delete: false,
        confirm: false,
        contextualMenu: false,
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser']),
    uploadEnabled() {
      return !(this.documentStatusClosed || this.documentStatusCompleted || this.documentStatusAccepted)
    },
    isWorkable() {
      if (this.loggedUser.role === 'user') {
        return !(this.documentStatusNotWorkable)
      }

      return false
    },
    documentStatusClosed() {
      return this.documentObj.document_setting_status.is_closed
    },
    documentStatusCompleted() {
      return this.documentObj.document_setting_status.is_completed
    },
    documentStatusAccepted() {
      return this.documentObj.document_setting_status.is_accepted
    },
    documentStatusNotWorkable() {
      return this.documentObj.document_setting_status.is_not_workable
    },
    uploadUrl() {
      return `${this.$apiUrl}procedures/${this.documentObj.procedure_id}/documents/${this.documentObj.id}`
    },
    currentProcedureId() {
      return this.isPluginVersion ? this.$route.params.id : this.documentObj.procedure_id
    },
  },
  methods: {
    onDragStart(ev) {
      // send the id of the element, so it can be moved into the folder
      ev.dataTransfer.setData('documentId', this.documentObj.id)
      ev.dataTransfer.setData('procedureId', this.documentObj.procedure_id)
    },
    closeContextualMenu() {
      this.isShow.contextualMenu = false
    },
    showContextualMenu(e) {
      e.preventDefault()
      this.isShow.contextualMenu = true
    },
    urgencyCard(priority) {
      if (this.noPriority) {
        return 'border-top:10px solid #c5cae9;'
      }
      if (priority === 'high') {
        return 'border-top:10px solid #ffccbc;'
      } if (priority === 'low') {
        return 'border-top:10px solid #c8e6c9;'
      }

      return 'border-top:10px solid #c5cae9;'
    },
    handleUploadFiles() {
      this.isShow.upload = true
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadAllWithAxios() {
      if (this.loading.fileDownloadAll[this.documentObj.id] === undefined) {
        this.$set(this.loading.fileDownloadAll, this.documentObj.id, true)
      } else {
        this.loading.fileDownloadAll[this.documentObj.id] = !this.fileDownloadAll[this.documentObj.id]
      }

      let url = ''
      if (this.isPluginVersion) {
        url = `${this.$apiUrl}ext/procedures/${this.procedureId}/documents/${this.documentObj.id}/download/all`
      } else {
        url = `${this.$apiUrl}procedures/${this.procedureId}/documents/${this.documentObj.id}/download/all`
      }

      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, `${this.documentObj.title}.zip`)
          this.loading.fileDownloadAll[this.documentObj.id] = false
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', { msg: 'Non hai i permessi necessari per scaricare i files.', type: 'error' })
              break
            default:
              this.$store.dispatch('set_alert', { msg: 'Errore nel download, si prega di riprovare.', type: 'error' })
              break
          }
          this.loading.fileDownloadAll[this.documentObj.id] = false
        })
    },
    reloadData() {
      this.$emit('reloadData')
    },
    goToDocumentDetail(did) {
      this.$router.push({
        name: this.documentRoute,
        params: {
          id: this.procedureId,
          did,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
