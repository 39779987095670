<template>
  <div
    class="d-flex"
  >
    <v-btn
      v-if="!noBackButton && backButtonRoute !== this.$router.currentRoute.path"
      class="my-3"
      :to="backButtonRoute"
    >
      {{ backButtonText }}
    </v-btn>
    <v-btn
      v-if="downloadEntireProcedure && procedureNumFiles > 0"
      :color="btnColor"
      :dark="btnDark"
      :loading="loadingDownloadEntireProcedure"
      class="my-3"
      @click="clickDownloadEntireProcedure"
    >
      <v-icon>mdi-download</v-icon>
      SCARICA INTERA PRATICA
    </v-btn>
    <v-spacer />
    <v-btn
      v-if="showReloadResource"
      :color="btnColor"
      :dark="btnDark"
      rounded
      class="my-3 mx-2 hidden-xs-only"
      :loading="loadingReloadResource"
      @click="clickReloadResource"
    >
      <v-icon>mdi-reload</v-icon>
    </v-btn>
    <v-btn
      v-if="!noDocumentCreationButton && resourceFound"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 mx-2 hidden-xs-only"
      @click="clickCreateFolder"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddFolder }}
    </v-btn>
    <v-btn
      v-if="!noDocumentCreationButton && noResourceFound && !noResourceCreationButton"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 mx-2 hidden-xs-only"
      :loading="loadingCreateResource"
      @click="clickCreateResource"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddResource }}
    </v-btn>
    <v-btn
      v-if="!noDocumentCreationButton && resourceFound"
      :color="btnColor"
      :dark="btnDark"
      class="my-3 mx-2 hidden-xs-only"
      @click="clickCreateDocument"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddDocument }}
    </v-btn>
    <v-btn
      v-if="!noSurveyCreationButton"
      color="primary"
      class="my-3 hidden-xs-only"
      @click="clickCreateSurvey"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddSurvey }}
    </v-btn>
    <v-btn
      :color="btnColor"
      :dark="btnDark"
      class="my-3 hidden-sm-and-up"
      block
      @click="clickCreateDocument"
    >
      <v-icon>mdi-plus</v-icon>
      {{ dictBtnAddDocument }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureDetailHeader',
  props: {
    documentCreationEnabled: {
      type: Boolean,
      default: true,
    },
    loadingCreateResource: {
      type: Boolean,
      default: false,
    },
    noSurveyCreationButton: {
      type: Boolean,
      default: false,
    },
    noBackButton: {
      type: Boolean,
      default: false,
    },
    noDocumentCreationButton: {
      type: Boolean,
      default: false,
    },
    noResourceCreationButton: {
      type: Boolean,
      default: false,
    },
    noResourceFound: {
      type: Boolean,
      default: false,
    },
    downloadEntireProcedure: {
      type: Boolean,
      default: false,
    },
    loadingDownloadEntireProcedure: {
      type: Boolean,
      default: false,
    },
    procedureNumFiles: {
      type: Number,
      default: 0,
    },
    showReloadResource: {
      type: Boolean,
      default: false,
    },
    loadingReloadResource: {
      type: Boolean,
      default: false,
    },
    backButtonText: {
      type: String,
      default: 'Torna alla lista pratiche',
    },
    backButtonRoute: {
      type: String,
      default: '/procedures',
    },
  },
  computed: {
    ...mapGetters(['loggedUser', 'partnerSettings']),
    resourceFound() {
      return !this.noResourceFound && !this.noDocumentCreationButton
    },
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    dictBtnAddFolder() {
      return this.partnerSettings?.dictionary?.procedure?.folders?.add ?? 'Cartella'
    },
    dictBtnAddResource() {
      return this.partnerSettings?.dictionary?.procedure?.resources?.add ?? 'Risorsa'
    },
    dictBtnAddDocument() {
      return this.partnerSettings?.dictionary?.procedure?.documents?.add ?? 'Documento'
    },
    dictBtnAddSurvey() {
      return this.partnerSettings?.dictionary?.procedure?.surveys?.add ?? 'Questionario'
    },
    dictBtnDownloadEntireProcedure() {
      return this.partnerSettings?.dictionary?.procedure?.downloadEntireProcedure ?? 'Scarica intera pratica'
    },
  },
  methods: {
    clickCreateFolder() {
      this.$emit('clickCreateFolder')
    },
    clickCreateDocument() {
      this.$emit('clickCreateDocument')
    },
    clickCreateSurvey() {
      this.$emit('clickCreateSurvey')
    },
    clickCreateResource() {
      this.$emit('clickCreateResource')
    },
    clickDownloadEntireProcedure() {
      this.$emit('clickDownloadEntireProcedure')
    },
    clickReloadResource() {
      this.$emit('clickReloadResource')
    },
  },
}
</script>
