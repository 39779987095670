<template>
  <div class="d-flex align-center">
    <v-avatar
      size="44"
      :color="color"
      rounded
      class="elevation-1"
    >
      <v-icon
        dark
        color="white"
        size="30"
      >
        {{ icon }}
      </v-icon>
    </v-avatar>
    <div class="ms-3">
      <p class="text-xs mb-0">
        {{ title }}
      </p>
      <h3 class="text-xl font-weight-semibold">
        {{ total }}
      </h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GenericInfoAvatar',
  props: {
    icon: {
      type: String,
      default: 'mdi-trending-up',
    },
    title: {
      type: String,
      default: 'Titolo',
    },
    total: {
      type: [String, Number],
      default: '0',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      statisticsData: [
        {
          title: 'Sales',
          total: '245k',
        },
      ],
    }
  },
}
</script>
