<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Conferma Documento</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>Confermando il documento invierai una notifica al professionista comunicandogli che hai caricato tutta la documentazione richiesta.</p>
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                class="ma-2"
                :disabled="loading"
                @click="closeModal"
              >
                Annulla
              </v-btn>
              <v-btn
                color="primary"
                class="ma-2"
                :loading="loading"
                @click.prevent="confirmDocument"
              >
                Conferma
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'ConfirmDocument',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    documentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {

  },
  methods: {
    async confirmDocument() {
      try {
        this.loading = true
        await this.$api.confirmDocument(this.documentData.procedure_id, this.documentData.id, this.documentData)
        await this.$store.dispatch('set_alert', { msg: 'Documentazione confermata con successo', type: 'success' })
        await this.$emit('reload-data')
        this.closeModal()
      } catch (e) {
        this.loading = false
        await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
      }
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
