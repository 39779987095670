<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aggiungi una cartella</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.name"
                  label="Nome"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  :color="btnColor"
                  :dark="btnDark"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'FolderCreate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    folderRef: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialForm: {},
      form: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  watch: {
    folderRef(val) {
      if (val) {
        this.form.parent_id = val
        this.initialForm.parent_id = val
      }
    },
  },
  mounted() {
    this.setInitialValues()

    // if this.parentId is not null, it means that we are creating a subfolder
    // so add to form the folderId
    if (this.folderRef) {
      this.form.parent_id = this.folderRef
      this.initialForm.parent_id = this.folderRef
    }
  },
  methods: {
    setInitialValues() {
      this.form = {
        ...this.initialForm,
      }
    },
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true

          if (this.isPluginVersion) {
            await this.$api.createPluginFolderProcedure(this.$route.params.id, this.form)
          } else {
            await this.$api.createFolderProcedure(this.$route.params.id, this.form)
          }

          this.isShow = false
          await this.$store.dispatch('set_alert', { msg: 'Cartella creata con successo', type: 'success' })
          this.$emit('reload-data')
          await this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
        }
      }
    },
    async closeModal() {
      await this.$refs.form.reset()
      await this.$emit('close')
      await this.setInitialValues()
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
