<template
  @click:outside="closeThisMenu"
>
  <v-menu
    v-model="isMenuOpen"
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-if="!noProcedureUpdate && !isArchived"
        @click="editModalClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-pencil
          </v-icon>
          Modifica
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!isArchived"
        @click="showInviteClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-account-arrow-left
          </v-icon>
          Invita Cliente
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!noInviteProfessional && !isArchived"
        @click="showInviteProClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-account-arrow-left
          </v-icon>
          Invita Professionista
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!noProcedureArchive && !isArchived"
        @click="archiveModalClick"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-text-box-check
          </v-icon>
          Archivia
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isArchived"
        @click="reactivate"
      >
        <v-list-item-title>
          <v-icon
            left
            small
          >
            mdi-pencil
          </v-icon>
          Riattiva Pratica
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureMenu',
  props: {
    noProcedureArchive: {
      type: Boolean,
      default: false,
    },
    noProcedureUpdate: {
      type: Boolean,
      default: false,
    },
    noInviteProfessional: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      required: false,
    },
    isArchived: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  watch: {
    showMenu(newVal) {
      this.isMenuOpen = newVal
    },
    isMenuOpen(newVal) {
      if (newVal === false) {
        this.$emit('closeMenu')
      }
    },
  },
  methods: {
    editModalClick() {
      this.$emit('editProcedure')
    },
    showInviteClick() {
      this.$emit('showInvite')
    },
    showInviteProClick() {
      this.$emit('showInvitePro')
    },
    archiveModalClick() {
      this.$emit('archiveProcedure')
    },
    reactivate() {
      this.$emit('reactivate')
    },
    closeThisMenu() {
      this.$emit('closeMenu')
    },
  },
}

</script>
