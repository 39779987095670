import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.company)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":_vm.color,"size":_vm.size}},'v-avatar',attrs,false),on),[(_vm.getAvatar(_vm.company.id))?_c(VImg,{attrs:{"src":_vm.getAvatar(_vm.company.id)}}):_c('span',[_c('span',[_vm._v(_vm._s(_vm.company.initials))])])],1)]}}],null,false,41594548)},[_c('span',[_vm._v(_vm._s(_vm.company.name))])]):_vm._e(),(_vm.invite)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":_vm.color,"size":_vm.size}},'v-avatar',attrs,false),on),[_c('span',[_vm._v("I")])])]}}],null,false,2313462685)},[_c('span',[_vm._v(_vm._s(_vm.invite.target_user_email)+" - Invito")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }