import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Modifica Pratica")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveProcedure.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Titolo","outlined":"","required":"","dense":"","rules":[_vm.formRules.required],"hint":"Il titolo della pratica"},model:{value:(_vm.localProcedureData.title),callback:function ($$v) {_vm.$set(_vm.localProcedureData, "title", $$v)},expression:"localProcedureData.title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Descrizione","rows":"3","dense":"","outlined":"","hint":"Descrizione estesa della pratica"},model:{value:(_vm.localProcedureData.description),callback:function ($$v) {_vm.$set(_vm.localProcedureData, "description", $$v)},expression:"localProcedureData.description"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Riferimento interno","outlined":"","required":"","dense":"","hint":"Il riferimento interno aziendale"},model:{value:(_vm.localProcedureData.reference),callback:function ($$v) {_vm.$set(_vm.localProcedureData, "reference", $$v)},expression:"localProcedureData.reference"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VMenu,{ref:"menu_date",attrs:{"close-on-content-click":false,"return-value":_vm.menu_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.menu_date=$event},"update:return-value":function($event){_vm.menu_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data di scadenza prevista","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.localProcedureData.expiry),callback:function ($$v) {_vm.$set(_vm.localProcedureData, "expiry", $$v)},expression:"localProcedureData.expiry"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_date),callback:function ($$v) {_vm.menu_date=$$v},expression:"menu_date"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","locale":"it-IT","first-day-of-week":1},model:{value:(_vm.localProcedureData.expiry),callback:function ($$v) {_vm.$set(_vm.localProcedureData, "expiry", $$v)},expression:"localProcedureData.expiry"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu_date = false}}},[_vm._v(" Chiudi ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu_date.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveProcedure.apply(null, arguments)}}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }