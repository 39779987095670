<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica Pratica</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="saveProcedure"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localProcedureData.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                  hint="Il titolo della pratica"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localProcedureData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                  hint="Descrizione estesa della pratica"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localProcedureData.reference"
                  label="Riferimento interno"
                  outlined
                  required
                  dense
                  hint="Il riferimento interno aziendale"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-menu
                  ref="menu_date"
                  v-model="menu_date"
                  :close-on-content-click="false"
                  :return-value.sync="menu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="localProcedureData.expiry"
                      label="Data di scadenza prevista"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="localProcedureData.expiry"
                    no-title
                    scrollable
                    locale="it-IT"
                    :first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu_date = false"
                    >
                      Chiudi
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_date.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="saveProcedure"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditProcedure',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    procedureData: Object,
  },
  data() {
    return {
      form: {},
      importModal: false,
      rules: {
        required: value => !!value || 'Required.',
        emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
        cfRule: v => /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/.test(v) || 'not valid',
      },
      localProcedureData: Object,
      loading: false,
      menu_date: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'procedureList']),
  },
  watch: {
    isShow(newShow) {
      if (newShow) {
        this.localProcedureData = JSON.parse(JSON.stringify(this.procedureData))
        this.localProcedureData.expiry = this.localProcedureData.expiry.substr(0, 10)
      }
    },
  },
  methods: {
    async saveProcedure() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.editProcedure(this.procedureData.id, this.localProcedureData)
          this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Pratica Modificata con successo', type: 'success' })

          this.closeModal()
        } catch (e) {
          this.loading = false
          this.procedure_id = null
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.procedure_id = null
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
