<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Invita cliente nella pratica</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formInvite"
            @submit.prevent="inviteUser"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="formInvite.email"
                  label="Email"
                  type="email"
                  outlined
                  required
                  dense
                  :rules="[formRules.required,formRules.emailRules]"
                  :disabled="loadingBtn"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="formInvite.role"
                  label="Ruolo"
                  outlined
                  required
                  dense
                  :items="[{'text':'Utente','value':'user'}]"
                  :rules="[formRules.required]"
                  :disabled="loadingBtn"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loadingBtn"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loadingBtn"
                  :disabled="loadingBtn"
                  @click.prevent="inviteUser"
                >
                  Invita
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditProcedure',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    procedureData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formInvite: {
        role: 'user',
      },
      loadingBtn: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'procedureList']),
  },
  methods: {
    async inviteUser() {
      if (this.$refs.formInvite.validate()) {
        try {
          this.loadingBtn = true
          await this.$api.inviteUser(this.procedureData.id, this.formInvite)
          await this.$store.dispatch('set_alert', { msg: 'Invito spedito con successo', type: 'success' })
          this.closeModal()
          this.loadingBtn = false
        } catch (e) {
          this.loadingBtn = false
          this.procedure_id = null
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.procedure_id = null
      this.$refs.formInvite.reset()
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
