import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Lista Permessi Cartella")])]),_c(VCardText,[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},_vm._l(([_vm.subgroupsPermissions,_vm.companiesPermissions,_vm.usersPermissions]),function(entity_type,eindex){return _c(VRow,{key:eindex,staticClass:"ma-0"},[_c(VCol,{staticClass:"ma-0",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"mt-2 elevation-1",attrs:{"title":"Permessi Cartella","hide-default-footer":"","headers":[
                    {
                      text: 'Nome',
                      value: 'entity_name'
                    },
                    {
                      text: 'Lettura',
                      value: 'read'
                    },
                    {
                      text: 'Scrittura',
                      value: 'write'
                    },
                    {
                      text: 'Cancellazione',
                      value: 'delete'
                    },
                    {
                      text: 'Azioni',
                      value: 'actions'
                    }
                  ],"items":entity_type},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.entityNameFromTypeId(item.entity_type, item.entity_id)))]),_c('td',[_c(VSwitch,{attrs:{"value":item.read,"true-value":1,"false-value":0,"disabled":_vm.loading},on:{"change":function($event){return _vm.updatePermission(item)}},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})],1),_c('td',[_c(VSwitch,{attrs:{"value":item.write,"true-value":1,"false-value":0,"disabled":_vm.loading},on:{"change":function($event){return _vm.updatePermission(item)}},model:{value:(item.write),callback:function ($$v) {_vm.$set(item, "write", $$v)},expression:"item.write"}})],1),_c('td',[_c(VSwitch,{attrs:{"value":item.delete,"true-value":1,"false-value":0,"disabled":_vm.loading},on:{"change":function($event){return _vm.updatePermission(item)}},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})],1),_c('td',[_c(VBtn,{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.deletePermission(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)])]}},{key:"no-data",fn:function(){return [_vm._v(" Nessun elemento trovato. ")]},proxy:true}],null,true)})],1)],1)}),1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loading},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":_vm.btnColor,"dark":_vm.btnDark,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.openManagePermissions.apply(null, arguments)}}},[_vm._v(" Gestisci ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }