<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Riattiva Pratica</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <v-col
              cols="12"
            >
              <p>Riattivando la pratica, i clienti e i collaboratori potranno tornare a caricare documenti in essa.</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            :disabled="loading"
            @click="closeModal"
          >
            Chiudi
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            class="ma-2"
            :loading="loading"
            @click.prevent="reactivateProcedure"
          >
            Riattiva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'ReactivateProcedure',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    procedureData: {
      type: Object,
      required: true,
    },
    redirectToDetail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async reactivateProcedure() {
      this.loading = true
      const respReactivate = await this.$api.reactivateProcedure(this.procedureData.id)
      if (respReactivate.data.status === 'success') {
        await this.$store.dispatch('getProcedures')
        await this.$store.dispatch('setProductPercentages')
        await this.$store.dispatch('setProductFunctionalities')
        await this.$store.dispatch('set_alert', { msg: respReactivate.data.message, type: 'success' })
        this.closeModal()
        this.loading = false
        if (this.redirectToDetail) {
          await this.$router.push({ name: 'procedureView', params: { id: this.procedureData.id } })
        }
      } else {
        await this.$store.dispatch('set_alert', { msg: respReactivate.data.message, type: 'error' })
        this.closeModal()
        this.loading = false
      }
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
