<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Gestisci Permessi Cartella</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="permissionGroupFolderForm"
            @submit.prevent="permissionsFolder"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-select
                  v-model="entityType"
                  :items="entityTypes"
                  item-text="name"
                  item-value="id"
                  label="Target"
                  outlined
                  dense
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-if="showSecondField"
                  v-model="targetSelector"
                  :items="targetSelectorItems"
                  item-text="name"
                  item-value="id"
                  label="Target"
                  outlined
                  dense
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <!-- create a column for each permission with a checkbox switch (read, write, delete) -->
              <v-col
                v-if="showThirdField"
                cols="12"
              >
                <v-switch
                  v-for="(permission, index) in permissions"
                  :key="index"
                  v-model="formPermissions[permission.id]"
                  :value="formPermissions[permission.id]"
                  :true-value="1"
                  :false-value="0"
                  :label="permission.name"
                  :disabled="loading"
                ></v-switch>
              </v-col>

              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  :color="btnColor"
                  :dark="btnDark"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="permissionsFolder"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ManagePermissionsFolder',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    folderData: {
      type: Object,
      default: () => ({}),
    },
    apiTargetId: {
      type: Number,
      required: true,
    },
    apiGetMethod: {
      type: String,
      required: true,
    },
    apiSetMethod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      entityTypes: [
        { id: 'user', name: 'Utente' },
        { id: 'company', name: 'Aziende' },
        { id: 'subgroup', name: 'Sottogruppi' },
      ],
      permissions: [
        { id: 'read', name: 'Lettura' },
        { id: 'write', name: 'Scrittura' },
        { id: 'delete', name: 'Cancellazione' },
      ],
      formPermissions: {
        read: false,
        write: false,
        delete: false,
      },
      entityType: null,
      targetSelector: null,
      showSecondField: false,
      targetSelectorItems: [],
      showThirdField: false,
      resourceFound: false,
      resourceId: null,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings', 'groupSubgroupsList', 'groupCompaniesList', 'groupUsersList']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  watch: {
    entityType(val) {
      this.showSecondField = !!val
      this.showThirdField = false
      this.resourceFound = false
      this.resourceId = null
      switch (val) {
        case 'user':
          // extract from groupUsersList id and fullName fields and put them into targetSelectorItems
          // the name node has to be composed by user.fullName and the company name based on company_id
          this.targetSelectorItems = this.groupUsersList.map(user => ({
            id: user.id,
            name: `${user.fullName} (${this.groupCompaniesList.find(company => company.id === user.company_id)?.name})`,
          }))
          break
        case 'company':
          this.targetSelectorItems = this.groupCompaniesList
          break
        case 'subgroup':
          this.targetSelectorItems = this.groupSubgroupsList
          break
        default:
          this.targetSelectorItems = []
          break
      }
    },
    targetSelector(val) {
      // ask to the api the existance of the folder for the selected target
      // if exists set the resourceFound to true and set the form values based on the response to the api
      // if not set the resourceFound to false and set the form values to false
      this.resourceFound = false
      this.showThirdField = false
      this.form = {}
      if (val) {
        // make a promise
        this.$api[this.apiGetMethod](this.apiTargetId, this.folderData.id)
          .then(response => {
            // response.data contains the permissions for the folder
            // find the permission for the selected target and set the form values based on the response
            // entityType is the entity_type of the response and targetSelector is the entity_id of the response
            const permission = response.data.find(
              item => item.entity_type === this.entityType && item.entity_id === val,
            )

            if (permission) {
              this.permissions.forEach(item => {
                this.formPermissions[item.id] = permission[item.id]
              })
              this.resourceFound = true
              this.resourceId = permission.id
            } else {
              this.permissions.forEach(item => {
                this.formPermissions[item.id] = false
                this.resourceId = null
              })
            }

            this.showThirdField = true
          })
          .catch(() => {
            this.resourceFound = false
            this.showThirdField = false
            this.resourceId = null
          })
      }
      this.showThirdField = !!val
    },
  },
  methods: {
    async permissionsFolder() {
      if (this.$refs.permissionGroupFolderForm.validate()) {
        try {
          this.loading = true
          await this.$api[this.apiSetMethod](this.apiTargetId, this.folderData.id, {
            entity_type: this.entityType,
            entity_id: this.targetSelector,
            ...this.formPermissions,
            ...(this.resourceId ? { resource_id: this.resourceId } : {}),
          })

          await this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Permessi aggiornati con successo', type: 'success' })
          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$refs.permissionGroupFolderForm.reset()
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
