<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Modifica Documento</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="editDocument"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="localDocumentData.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="localDocumentData.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                v-if="!noPriority"
                cols="12"
              >
                <v-select
                  v-model="localDocumentData.priority"
                  :items="priorityOpts"
                  label="Priorità"
                  outlined
                ></v-select>
              </v-col>
              <v-col
                v-if="!noAction"
                cols="12"
              >
                <v-select
                  v-model="localDocumentData.company_action_id"
                  :items="companySettingsFilteredList('document.action').map(act => ({ text: act.name, value: act.id }))"
                  value=""
                  label="Azione"
                  outlined
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <v-col
                v-if="!noStatus"
                cols="12"
              >
                <v-select
                  v-model="localDocumentData.company_status_id"
                  :items="companySettingsFilteredList('document.status').map(sta => ({ text: sta.name, value: sta.id }))"
                  value="1"
                  label="Stato"
                  outlined
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  :color="btnColor"
                  :dark="btnDark"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="editDocument"
                >
                  Salva
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'EditDocument',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    documentData: {
      type: Object,
      default: () => ({}),
    },
    noPriority: {
      type: Boolean,
      default: false,
    },
    noAction: {
      type: Boolean,
      default: false,
    },
    noStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      priorityOpts: [
        {
          text: 'Normale',
          value: 'standard',
        },
        {
          text: 'Bassa priorità',
          value: 'low',
        },
        {
          text: 'Urgente',
          value: 'high',
        },
      ],
      localDocumentData: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules', 'companySettingsFilteredList', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  watch: {
    isShow(newShow) {
      if (newShow) {
        this.localDocumentData = JSON.parse(JSON.stringify(this.documentData))
      }
    },
  },
  methods: {
    async editDocument() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          if (this.isPluginVersion) {
            await this.$api.editPluginDocument(this.documentData.procedure_id, this.documentData.id, this.localDocumentData)
          } else {
            await this.$api.editDocument(this.documentData.procedure_id, this.documentData.id, this.localDocumentData)
          }

          this.$emit('reload-data')
          await this.$store.dispatch('set_alert', { msg: 'Documento Modificato con successo', type: 'success' })
          this.closeModal()
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
