import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Invita professionista nella pratica")])]),_c(VCardText,[_c('p',[_vm._v("Inserisci l'indirizzo email del professionista per invitarlo nella pratica.")]),_c(VForm,{ref:"formInvite",on:{"submit":function($event){$event.preventDefault();return _vm.inviteProfessional.apply(null, arguments)}}},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Email","type":"email","outlined":"","required":"","dense":"","rules":[_vm.formRules.required,_vm.formRules.emailRules],"disabled":_vm.loadingBtn},model:{value:(_vm.formInvite.email),callback:function ($$v) {_vm.$set(_vm.formInvite, "email", $$v)},expression:"formInvite.email"}})],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"disabled":_vm.loadingBtn},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")]),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn},on:{"click":function($event){$event.preventDefault();return _vm.inviteProfessional.apply(null, arguments)}}},[_vm._v(" Invita ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }