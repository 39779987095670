<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Lista Permessi Cartella</span>
        </v-card-title>
        <v-card-text>
          <v-row class="ma-0">
            <!-- Create a block of code for each permission type -->
            <v-col
              cols="12"
            >
              <v-row
                v-for="(entity_type, eindex) in [subgroupsPermissions,companiesPermissions,usersPermissions]"
                :key="eindex"
                class="ma-0"
              >
                <v-col
                  class="ma-0"
                  cols="12"
                >
                  <!-- Create a table for each permission type -->
                  <!-- The table header contains 2 columns (entity name and read/write/delete) -->
                  <v-data-table
                    title="Permessi Cartella"
                    hide-default-footer
                    :headers="[
                      {
                        text: 'Nome',
                        value: 'entity_name'
                      },
                      {
                        text: 'Lettura',
                        value: 'read'
                      },
                      {
                        text: 'Scrittura',
                        value: 'write'
                      },
                      {
                        text: 'Cancellazione',
                        value: 'delete'
                      },
                      {
                        text: 'Azioni',
                        value: 'actions'
                      }
                    ]"
                    :items="entity_type"
                    class="mt-2 elevation-1"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ entityNameFromTypeId(item.entity_type, item.entity_id) }}</td>
                        <td>
                          <!-- Put a checkbox switch for each permission type -->
                          <v-switch
                            v-model="item.read"
                            :value="item.read"
                            :true-value="1"
                            :false-value="0"
                            :disabled="loading"
                            @change="updatePermission(item)"
                          ></v-switch>
                        </td>
                        <td>
                          <v-switch
                            v-model="item.write"
                            :value="item.write"
                            :true-value="1"
                            :false-value="0"
                            :disabled="loading"
                            @change="updatePermission(item)"
                          ></v-switch>
                        </td>
                        <td>
                          <v-switch
                            v-model="item.delete"
                            :value="item.delete"
                            :true-value="1"
                            :false-value="0"
                            :disabled="loading"
                            @change="updatePermission(item)"
                          ></v-switch>
                        </td>
                        <td>
                          <v-btn
                            icon
                            :disabled="loading"
                            @click="deletePermission(item)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      Nessun elemento trovato.
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                class="ma-2"
                :disabled="loading"
                @click="closeModal"
              >
                Chiudi
              </v-btn>
              <v-btn
                :color="btnColor"
                :dark="btnDark"
                class="ma-2"
                :loading="loading"
                @click.prevent="openManagePermissions"
              >
                Gestisci
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PermissionsFolder',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    folderData: {
      type: Object,
      default: () => ({}),
    },
    apiTargetId: {
      type: Number,
      required: true,
    },
    apiGetMethod: {
      type: String,
      required: true,
    },
    apiEditMethod: {
      type: String,
      required: true,
    },
    apiDeleteMethod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPageFullyLoaded: false,
      form: {},
      loading: false,
      permissionsList: [],
    }
  },
  computed: {
    ...mapGetters(['formRules', 'partnerSettings', 'groupSubgroupsList', 'groupCompaniesList', 'groupUsersList']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    subgroupsPermissions() {
      return this.permissionsList.filter(permission => permission.entity_type === 'subgroup') ?? []
    },
    companiesPermissions() {
      return this.permissionsList.filter(permission => permission.entity_type === 'company') ?? []
    },
    usersPermissions() {
      return this.permissionsList.filter(permission => permission.entity_type === 'user') ?? []
    },
  },
  watch: {
    isShow() {
      this.$api[this.apiGetMethod](this.apiTargetId, this.folderData.id)
        .then(response => {
          this.permissionsList = response.data
        })
        .catch(error => {
          // do nothing
        })
    },
    folderData() {
      this.$api[this.apiGetMethod](this.apiTargetId, this.folderData.id)
        .then(response => {
          this.permissionsList = response.data
        })
        .catch(error => {
          // do nothing
        })
    },
  },
  methods: {
    async updatePermission(permission) {
      this.loading = true
      await this.$api[this.apiEditMethod](this.apiTargetId, this.folderData.id, permission.id, permission)
        .then(response => {
          if (response.data.status === 'success') {
            this.$store.dispatch('set_alert', { msg: 'Permesso salvato con successo', type: 'success' })
            this.loading = false
          }
        })
        .catch(error => {
          this.$store.dispatch('set_alert', { msg: 'Permesso non salvato', type: 'error' })
          this.loading = false
        })
    },
    async deletePermission(permission) {
      // ask for confirmation and execute the following code
      if (confirm('Sei sicuro di voler eliminare questo permesso?')) {
        this.loading = true
        await this.$api[this.apiDeleteMethod](this.apiTargetId, this.folderData.id, permission.id)
          .then(response => {
            if (response.data.status === 'success') {
              this.$store.dispatch('set_alert', { msg: 'Permesso eliminato con successo', type: 'success' })
              this.loading = false
              this.$emit('reload-data')
            }
          })
          .catch(error => {
            this.$store.dispatch('set_alert', { msg: 'Permesso non eliminato', type: 'error' })
            this.loading = false
          })
      }
    },
    entityNameFromTypeId(entityType, entityId) {
      switch (entityType) {
        case 'subgroup':
          return this.groupSubgroupsList.find(subgroup => subgroup.id === entityId)?.name ?? ''
        case 'company':
          return this.groupCompaniesList.find(company => company.id === entityId)?.name ?? ''
        case 'user':
          return this.groupUsersList.find(user => user.id === entityId)?.name ?? ''
        default:
          return ''
      }
    },
    openManagePermissions() {
      this.$emit('open-manage-permissions')
    },
    closeModal() {
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
