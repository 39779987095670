<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div>
          <procedure-detail-header
            v-if="loggedUser.role === 'professional'"
            no-survey-creation-button
            back-button-text="Indietro"
            :back-button-route="calculateBackRoute"
            :no-document-creation-button="!canHandleResource"
            :no-resource-creation-button="!canHandleResource"
            show-reload-resource
            :procedure-num-files="procedureData.document_files_count"
            :no-resource-found="!pluginSettings.resourceFound"
            :loading-create-resource="loading.createResource"
            :loading-download-entire-procedure="loading.downloadEntireProcedure"
            :loading-reload-resource="loading.reloadResource"
            @clickCreateDocument="isShow.docCreate=true"
            @clickCreateFolder="isShow.folderCreate=true"
            @clickCreateResource="createResource"
            @clickDownloadEntireProcedure="downloadEntireProcedure(procedureData.title+'.zip')"
            @clickReloadResource="reloadResource"
          />

          <procedure-card
            :procedure-obj="procedureData"
            :procedure-enter-btn="false"
            :redirect-to-detail="true"
            no-title-block
            no-subtitle-block
            no-status-icon
            no-survey-icon
            no-procedure-update
            no-invite-professional
            no-procedure-archive
            header-small
          />

          <v-divider></v-divider>

          <!-- BREADCRUMB -->
          <v-row class="mt-0">
            <v-col cols="12">
              <v-breadcrumbs
                class="pl-0"
                large
                :items="calculateBreadcrumb"
                divider=">"
              >
              </v-breadcrumbs>
            </v-col>
          </v-row>

          <!-- FOLDERS -->
          <v-row class="mt-3">
            <v-col
              v-for="(folder,idx) in currentLevelFolders"
              :key="'F'+idx"
              cols="12"
              sm="12"
              md="3"
            >
              <folder-card
                folder-route="pluginProcedureFolderView"
                :folder-procedure-id="procedureData.ext_id"
                :folder-obj="folder"
                :folder-permissions="folder.folder_permissions"
                :go-to-folder-target-id="procedureData.ext_id"
                :api-target-id="procedureData.ext_id"
                :api-target-perm-id="procedureData.id"
                :api-routes="apiRoutes"
                @reloadData="reloadData"
                @droppedItem="setIsDropped"
              />
            </v-col>
          </v-row>

          <!--- DOCUMENTS -->
          <v-row>
            <v-col
              v-for="(doc,idx) in currentLevelDocuments"
              :key="'D'+idx"
              cols="12"
              sm="12"
              md="4"
            >
              <document-card
                is-plugin-version
                is-draggable
                no-priority
                no-action
                no-status
                document-route="pluginProcedureDocumentsView"
                :can-handle-resource="canHandleResource"
                :document-obj="doc"
                :procedure-id="procedureData.ext_id"
                :is-dropped="isDropped[doc.id]"
                @reloadData="reloadData"
              />
            </v-col>
          </v-row>

          <create-folder
            v-if="isPageFullyLoaded"
            is-plugin-version
            :folder-ref="currentFolder.unique_ref"
            :is-show="isShow.folderCreate"
            @close="isShow.folderCreate=false"
            @reload-data="reloadData"
          />
          <create-document
            v-if="isPageFullyLoaded"
            is-plugin-version
            no-priority
            no-action
            no-status
            :folder-id="currentFolder.id"
            :is-show="isShow.docCreate"
            @close="isShow.docCreate=false"
            @reload-data="reloadData"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcedureDetailHeader from '@/components/section-headers/procedure-detail-header.vue'
import ProcedureCard from '@/components/cards/procedure-card.vue'
import DocumentCard from '@/components/cards/document-card.vue'
import CreateDocument from '@/components/modals/create-document.vue'
import CreateFolder from '@/components/modals/create-folder.vue'

import { mapGetters } from 'vuex'
import Api from '@/plugins/api'
import FolderCard from '@/components/cards/folder-card.vue'

export default {
  name: 'PluginProcedure',
  components: {
    FolderCard,
    CreateFolder,
    ProcedureDetailHeader,
    ProcedureCard,
    CreateDocument,
    DocumentCard,
  },
  data() {
    return {
      isDropped: {},
      isPageFullyLoaded: false,
      isShow: {
        docCreate: false,
        folderCreate: false,
      },
      loading: {
        createResource: false,
        reloadResource: false,
        downloadEntireProcedure: false,
      },
      procedureData: {},
      currentFolder: {},
      mapExpirationChip: {
        expired: 'Scaduta',
      },
      pluginSettings: {
        resourceFound: null,
      },
      isTheCreator: false,
      externalRights: false,
      apiRoutes: {
        editApiMethod: 'editPluginFolder',
        deleteApiMethod: 'destroyPluginFolder',
        editDocumentApiMethod: 'editPluginDocument',
        permGetApiMethod: 'getFolderPermissions',
        permSetApiMethod: 'setFolderPermissions',
        permEditApiMethod: 'updateFolderPermission',
        permDeleteApiMethod: 'deleteFolderPermission',
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'procedureList']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    isAdmin() {
      return this.loggedUser.company_role === 'admin'
    },
    canHandleResource() {
      return true
      //return this.isOwner || this.isAdmin || this.isTheCreator
    },
    calculateBreadcrumb() {
      // create an array of objects containing the nodes: text, disabled, href
      // the first node has always the name of the procedure and it goes to the procedure detail page
      const breadcrumb = [
        {
          text: this.procedureData.title,
          disabled: false,
          to: `/plugin-procedure/${this.procedureData.ext_id}/view`,
        },
      ]

      const tempFolders = []
      const findParentFolder = folder => {
        if (this.procedureData && this.procedureData.id) {
          const parentFolder = this.procedureData.folders.find(item => item.id === folder.parent_id)
          if (parentFolder) {
            // if it has a parent_id, return the route to the parent folder
            tempFolders.push({
              text: parentFolder.name,
              disabled: false,
              to: `/plugin-procedure/${this.procedureData.ext_id}/folder/${parentFolder.unique_ref}`,
            })
            findParentFolder(parentFolder)
          }
        }
      }

      findParentFolder(this.currentFolder)

      // reverse the array to have the correct order
      tempFolders.reverse()
      breadcrumb.push(...tempFolders)

      // the last node has always the name of the current folder and is disabled because is the current page
      breadcrumb.push({
        text: this.currentFolder.name,
        disabled: true,
      })

      return breadcrumb
    },
    calculateBackRoute() {
      if (this.procedureData && this.procedureData.ext_id) {
        // if currentFolder is defined, check if it has a parent_id and is not 0
        if (this.currentFolder && this.currentFolder.parent_id && this.currentFolder.parent_id !== 0) {
          // find the parent folder
          const parentFolder = this.procedureData.folders.find(item => item.id === this.currentFolder.parent_id)

          // if it has a parent_id, return the route to the parent folder
          return `/plugin-procedure/${this.procedureData.ext_id}/folder/${parentFolder.unique_ref}`
        }

        return `/plugin-procedure/${this.procedureData.ext_id}/view`
      }

      return `/plugin-procedure/${this.procedureData.ext_id}/view`
    },
    currentLevelFolders() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.procedureData.folders.filter(item => item.parent_id === this.currentFolder.id)
      }

      return []
    },
    currentLevelDocuments() {
      if (this.currentFolder && this.currentFolder.id && this.currentFolder.id !== 0) {
        // if it has a parent_id, return the route to the parent folder
        return this.procedureData.documents.filter(item => item.folder_id === this.currentFolder.id)
      }

      return []
    },
  },
  watch: {
    procedureList() {
      this.getData()
    },
    '$route.params.fid': function () {
      this.setCurrentFolder()
    },
  },
  async created() {
    await this.getData()
      .then(() => this.setCurrentFolder())
      .then(() => this.setPageFullyLoaded())
  },
  async mounted() {
    // before getting the procedure data, check if the procedure list already contains the procedure data
    if (!this.procedureList.some(item => item.ext_id === parseInt(this.$route.params.id, 10))) {
      if (this.isPageFullyLoaded) {
        try {
          await this.$store.dispatch('getPluginProcedure', parseInt(this.$route.params.id, 10))
          if (this.procedureList.some(item => item.ext_id === parseInt(this.$route.params.id, 10))) {
            await this.getData()
            await this.setResourceFound(true)
          } else {
            await this.setResourceFound(false)
          }
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      await this.getData()
      await this.setResourceFound(true)
    }
  },
  methods: {
    async reloadData() {
      await this.$store.dispatch('getPluginProcedure', this.procedureData.ext_id)
      this.procedureData = await this.procedureList.find(item => item.ext_id === this.procedureData.ext_id)
      this.isDropped = []
    },
    async setCurrentFolder() {
      if (this.procedureData && this.procedureData.folders) {
        this.currentFolder = await this.procedureData.folders.find(item => item.unique_ref === this.$route.params.fid)
      }
    },
    async getData() {
      if (this.procedureList.length > 0) {
        this.procedureData = await this.procedureList.find(item => item.ext_id === parseInt(this.$route.params.id, 10))
      }

      this.isTheCreator = await this.checkIfIsCreator()
    },
    async setResourceFound(status) {
      this.pluginSettings.resourceFound = status
    },
    async createResource() {
      this.loading.createResource = true
      try {
        const data = {
          title: this.$route.query.reference ?? `Spazio di Archiviazione ${this.$route.params.id}`,
          reference: this.$route.query.reference ?? `Spazio di Archiviazione ${this.$route.params.id}`,
          ext_id: this.$route.params.id,
        }
        const resp = await Api.createPluginProcedure(this.$route.params.id, data)
        if (resp.data.status === 'success') {
          await this.$store.dispatch('getPluginProcedure', this.$route.params.id)
          await this.getData()
          await this.setResourceFound(true)
          this.loading.createResource = false
        } else {
          this.loading.createResource = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    async reloadResource() {
      this.loading.reloadResource = true
      setTimeout(async () => {
        try {
          await this.$store.dispatch('getPartnerSettings')
          await this.$store.dispatch('getPluginProcedure', parseInt(this.$route.params.id, 10))
        } catch (error) {
          if (error.status === 404) {
            await this.setResourceFound(false)
          } else {
            console.log(error)
          }
        }
        this.loading.reloadResource = false
      }, 1000)
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    async checkIfIsCreator() {
      return true
    },
    async setPageFullyLoaded() {
      this.isPageFullyLoaded = true
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
  },
}
</script>

<style scoped>
    .container {
        max-width: 90%;
    }
    .v-breadcrumbs >>> a {
      color: rgb(143, 137, 152) !important;
    }
</style>
